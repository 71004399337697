import React from 'react'
import PostNewJob from './components/PostNewJob'

const SubmitJobs: React.FC = () => {
  return (
   <div>
    <PostNewJob />
   </div>
  )
}

export default SubmitJobs