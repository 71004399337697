import React from 'react'
import MyJobsTable from './components/MyJobsTable'



const SubmitJob: React.FC = () => {
  return (
   <div>
    <MyJobsTable />
   </div>
  )
}

export default SubmitJob