import React from 'react'
import FaqDetails from './components/FaqDetails'
import FooterSection from '../FooterSection'

const Faq = () => {
  return (
  <div>
     <FaqDetails />
     <FooterSection />
  </div>
  )
}

export default Faq