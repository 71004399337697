import React from 'react'
import CourseList from './components/CourseList'


const Courses = () => {
  return (
   <div>
     <CourseList />
   </div>
  )
}

export default Courses